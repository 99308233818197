import React from "react";
import ReactPaginate from "react-paginate";
import './Pagination.css';

function Pagination(props) {
    const pageLimitOptions = [12, 24, 48, 96, 288];

    return (        
        <div id="projects-pagination-container">
            {props.paginationVisibility ?
                <>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"projects-pagination-break"}
                        pageCount={props.pageCount}
                        marginPagesDisplayed={3}
                        pageRangeDisplayed={1}
                        onPageChange={props.handlePageClick}
                        containerClassName={"projects-pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"projects-active-page"}>
                    </ReactPaginate>
                    <select id="projects-pagination-perpage" onChange={(e) => props.handlePageLimit(e)}>
                        {pageLimitOptions.map((option, key) => {
                            return <option value={option} key={key}>{option}</option>
                        })}
                    </select>
                </>
            : null}
        </div>
        
    );
}

export default Pagination;