import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import NoProject from "./NoProject/NoProject";
import './OneProject.css'

const API_RESSOURCE = '/api/v1/projects';

function OneProject() {
    const [project, setProject] = useState(undefined); 
    const { projectID } = useParams();
    useEffect(() => {
        let project_api = API_RESSOURCE;
        if (projectID !== undefined) project_api += '/' + projectID;
        const fetchProject = async () => {
            const res = await fetch((project_api),);
            const json = await res.json();
            setProject(json);
        };
        fetchProject();
    }, [setProject, projectID]);

    return (
        <div className="app-child-node" id="home-wrapper">
                {project!==undefined ?
                <div className="singleProjectInformations">
                    <h2>{project?.data?.title}</h2>
                    <div className="singleProjectImage"><img src={project?.data?.image}/></div>
                    <div className="singleProjectCategories"><p><a href={"/projects?year=" + project?.data?.year} target="_blank"><span>{project?.data?.year}</span></a> - <a href={"/projects?profession=" + project.data.profession.id } target="_blank"><span>{project.data.profession.data.name}</span></a></p></div>
                    <div className="singleProjectDescription"><p>{project?.data?.description}</p></div>
                </div>
                : <NoProject></NoProject>}
        </div>
    );
}

export default OneProject;