import React, {useState, useEffect} from "react"
import Card from "../Card/Card";
import './Home.css';
import '../AppComponents/Spinner.css';
import LightBox from "../Projects/LightBox/LightBox";

const API_RESSOURCE = '/api/v1/projects';

function Home() {
    const [projects, setProjects] = useState(undefined); 

    const [lbVisibility, setLBVisibility] = useState(false);
    const [lbProject, setLBProject] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            const res = await fetch((API_RESSOURCE + '/last/6'),);
            const json = await res.json();
            setProjects(json);
        };
        fetchProjects();
    }, [setProjects]);

    const handleKeyUp = ({key}) =>{
        //console.log("keycall " + key + " lbVisibility: " + lbVisibility)
        if (key === "Escape" && lbVisibility) {
            setLBVisibility(false);
        }
        if (key === "ArrowLeft" && lbVisibility) {
            //console.log("called arrowleftup while lightboxed")
            switchProject("left")
        }
        if (key === "ArrowRight" && lbVisibility) {
            //console.log("called arrowrightup while lightboxed")
            switchProject("right")
        }
    }
    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [lbVisibility,lbProject]);
    const handleProjectClick = (project) => {
        setLBProject(project);
        setLBVisibility(true);
    }

    const switchProject = (d) => {
        //console.log(lbProject);
        switch (d) {
            case "left":
                if (projects.indexOf(lbProject)-1 >= 0) {
                    setLBProject(projects[projects.indexOf(lbProject)-1])
                }
                break;
            case "right":
                if (projects.indexOf(lbProject)+1 < projects.length) {
                    setLBProject(projects[projects.indexOf(lbProject)+1])
                }
                break;
            default:
                break;
        }
    }

    return (
        <div className="app-child-node" id="home-wrapper">
            <div id="projects-card-hero">
                <span id="projects-cards-hero-text">Découvre le meilleur de nos apprentis</span>
            </div>
            <span id="projects-cards-title">Derniers projets</span>
            <div id="projects-cards">
                {projects!==undefined ? projects.map((project, key) => {
                    return <Card project={project} key_index={key} onClick={() => {handleProjectClick(project)}} ></Card>;
                }):
                <div class="projects-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
            </div>
            <div id="projects-cards-more-container">
                <a href="/projects" id="projects-cards-more" >Voir plus</a>
            </div>
            <LightBox visibility={lbVisibility} setLBVisibility={setLBVisibility} project={lbProject} switchProject={switchProject}></LightBox>
        </div>
    );
}

export default Home