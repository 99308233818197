import React from "react";
import './LightBox.css'

function LightBox(props) {
    const getArrow = (d) => {
        let filepath = "Assets/Lightbox/" + d + "-arrow.svg"
        return <img src={filepath} alt={d} onClick={() => {props.switchProject(d)}}></img>
    }

    return (
        props.visibility ? 
            <div id="lightbox-container">
                <div className="light-close" onClick={()=> {props.setLBVisibility(false)}} ></div>
                    <div id="lightbox-document-container">
                        <div className="lightbox-document-arrows">
                            <div className="lightbox-document-arrows-close" onClick={()=> {props.setLBVisibility(false)}} ></div>
                            {getArrow("left")}
                        </div>
                        <div id="lightbox-document">
                            <div id="lightbox-document-inner">
                                <div id="lightbox-project-title">
                                    <h2>{props.project?.data?.title}</h2>
                                </div>
                                <div id="lightbox-project-image"><img src={props.project?.data?.image}/></div>
                                <div id="lightbox-project-categories"><p><a href={"/projects?year=" + props.project?.data?.year} target="_blank"><span>{props.project?.data?.year}</span></a> - <a href={"/projects?profession=" + props.project.data.profession.id } target="_blank"><span>{props.project.data.profession.data.name}</span></a></p></div>
                                <div id="lightbox-project-description"><p>{props.project?.data?.description}</p></div>
                            </div>
                        </div>
                    <div className="lightbox-document-arrows">
                        <div className="lightbox-document-arrows-close" onClick={()=> {props.setLBVisibility(false)}} ></div>
                        {getArrow("right")}
                    </div>
                </div>
            </div>
        : null
    );
}

export default LightBox;