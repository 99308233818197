import React from "react";
import './Bubble.css';

function Bubble(props) {
    return (
        <div className="projects-filter-bubble" key={props.key}>
            <span className="projects-filter-bubble-value">{props.value_friendly}</span>
            <span className="projects-filter-bubble-remove" onClick={() => (props.deleteKey(props.objkey))}>x</span>
        </div>
    );
}

export default Bubble;