import React from 'react';
import {Route, Switch} from 'react-router-dom';

import AppWrapper from './AppComponents/AppWrapper';
import Home from './Home/Home';
import Projects from './Projects/Projects';
import OneProject from './OneProject/OneProject';
import General404 from './General404/General404';
import './App.css';

function App() {
return (
    <div id="app">
      <AppWrapper>     
        <Switch>
          <Route path='/' exact>
            <Home/>
          </Route>
          <Route path='/projects' exact>
            <Projects/>
          </Route>
          <Route path='/projects/:projectID'>
            <OneProject/>
          </Route>
          <Route path="*">
            <General404 />
          </Route>
        </Switch>
      </AppWrapper>
    </div>
  );
}
export default App;