import React from 'react';

function handleClick(url) {
    window.open(url, '_blank');
}

function Footer() {
    const footerExternalLinks = [
        {name: 'Facebook', url: 'https://www.facebook.com/mediaeptm/'},
        {name: 'instagram', url: 'https://www.instagram.com/mediaeptm/'},
        {name: 'Youtube', url: 'https://www.youtube.com/channel/UCkSSJzLQB-3sv4KqJM-Wn8g'}
    ]
    return (
        <footer id="footer">
            <div id="footer-wrapper">
                <span id="copyright-claim">© Copyright EPTM / Tous droits réservés</span>
                <div id="footer-external-links">
                    {
                        footerExternalLinks.map((social, key) => {
                            return <img className="footer-external-link" key={key} onClick={() => {handleClick(social.url)}} src={"/Assets/Footer/" + social.name.toLowerCase() + "_icon.svg"} alt={social.name}></img>                            
                        })
                    }
                </div>
            </div>
        </footer>
    );
}

export default Footer