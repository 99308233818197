import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import image from "../Assets/backtotop.svg"
import "./AppWrapper.css"

function AppWrapper(props) {
    var scrollToTop = () =>{
        window.scrollTo(0, 0);
        document.getElementById("backtotop")?.classList?.add("hidden")
    }
    window.addEventListener("scroll",  ()=>{
        if (window.scrollY !== 0) {
            document.getElementById("backtotop")?.classList?.remove("hidden")
        }
    })
    return(
        <>        
            <Header></Header>
            {props.children}
            <div id="backtotop" className="app-backtotop-wrapper hidden" onClick={scrollToTop}>
                <img alt="back to top" src={image}></img>
            </div>
            <Footer></Footer>
        </>
    );
}

export default AppWrapper