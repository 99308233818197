import React from "react";
import {Link} from "react-router-dom";

import './Card.css'

function handleProfImage(id) {
    const professions=[{id:"info",image:"/Assets/Card/icone-metier-informatique.webp"},
        {id:"electro",image:"/Assets/Card/icone-metier-electronique.webp"},
        {id:"dci",image:"/Assets/Card/icone-metier-dessin-construction.webp"},
        {id:"auto",image:"/Assets/Card/icone-metier-automatique.webp"}];
    let output;
    professions.forEach(profession =>{
      if (profession.id === id) output = profession.image
    })
    return output;
}

function Card(props) {
    return (
        <div className="projects-cards-card" key={props.key_index} onClick={props.onClick}>
            <div className="card-hero-container">
                {/* <Link className="card-metadata-title" to={'projects/' + props.project.id}> */}
                    <img className="card-hero" src={props.project.data.image} alt={props.project.data.title}></img>
                {/* </Link> */}
            </div>
            <div className="card-metadata">
                <div className="card-metadata-text">
                {/* <Link className="card-metadata-title" to={'projects/' + props.project.id}>{props.project.data.title}</Link> */}
                    <p className="card-metadata-title" to={'projects/' + props.project.id}>{props.project.data.title}</p>
                    <div className="card-metadata-text-more">
                        <span className="card-metadata-more-year">{props.project.data.year}</span>
                        <span className="card-metadata-more-prof">{props.project.data.profession.data.name}</span>
                    </div>
                </div>                
                <div className="card-metadata-prof-container">
                    <img className="card-metadata-prof-image" src={handleProfImage(props.project.data.profession.id)} alt={props.project.data.profession.data.name}></img>
                </div>                
            </div>
        </div>
    );
}

export default Card;