import React from "react";
import Bubble from "./Bubble/Bubble";
import './Filter.css';


function Filter(props) {
    let profOptions = [{name:"Automaticien", id:"auto"},
                       {name:"Informaticien", id:"info"},
                       {name:"Électronicien", id:"electro"},
                       {name:"DCI", id:"dci"}];
    let yearOptions = [];
    for (let i=props.latestYear; i>=props.lastYear; i--) {
        yearOptions.push(i);        
    }
    const handleFriendlyBubble = (value) => {
        let friendlierValue = "";
        switch (value) {
            case "info":
                friendlierValue = "Informaticien";
                break;
            case "electro":
                friendlierValue = "Électronicien";
                break;
            case "auto":
                friendlierValue = "Automaticien";
                break;
            default:
                friendlierValue = value;
                break;
        }
        return friendlierValue;
    }

    return (
        <div id="projects-filter-container">
            {props.filterVisibility ?
                <>
                    <input type="text" className="projects-filter" id="projects-filter-keyword" placeholder="Rechercher" onChange={(e) => props.handleTextFilter(e)} />
                    <select className="projects-filter" onChange={(e) => (props.handleProfFilter(e))}>
                        <option value="" selected disabled hidden>Profession</option>                    
                        {profOptions.map((prof, key) => {
                            return <option value={prof.id} key={key}>{prof.name}</option>
                        })}
                    </select>
                    <select className="projects-filter" onChange={(e) => (props.handleYearFilter(e))}>
                        <option value="" selected disabled hidden>Année</option>
                        {[...yearOptions].map((year, key) => {return <option value={year} key={key}>{year}</option>})}
                    </select>
                    <div id="projects-filter-bubbles">
                        {[...Object.keys(props.filter)].map((objkey, key) => {
                            return props.filter[objkey] !== null ? 
                            <Bubble
                                key={key}
                                objkey={objkey}
                                value_friendly={handleFriendlyBubble(props.filter[objkey])}
                                value={props.filter[objkey]}
                                deleteKey={props.deleteKey}
                            ></Bubble>
                            : null
                        })}
                    </div>
                </>
                : null
            }
        </div>
    );
}

export default Filter;
