import React from 'react';

function Header() {
    return (
        <header id="header">
            <div id="header-main">
                <div id="header-main-content">
                    <a id="header-logo" href={window.location.protocol + '//' + window.location.host}> </a>
                    <a className="header-external-link" href="/projects">projets</a>
                    <a className="header-external-link" href="https://eptm.ch" target="_blank" rel="noreferrer">eptm</a>
                </div>
            </div>
        </header>
    );
}

export default Header;