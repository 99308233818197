import React from "react";
import './NoProject.css'

function NoProject() {
    return (
        <div className="app-child-node" id="noproject-wrapper">
            <div id="noproject-icon"></div>
            <div id="noproject-description">
                <p>Le projet est inexistant ou a été supprimé...</p>
                <p>Retourne sur la page d'accueil !</p>
            </div>
        </div>
    );
}

export default NoProject;