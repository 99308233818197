import React, { useEffect, useState, useRef } from "react";
import {useLocation} from 'react-router-dom';
import Card from "../Card/Card";
import Pagination from './Pagination/Pagination';
import Filter from "./Filter/Filter";
import LightBox from "./LightBox/LightBox";
import './Projects.css';
import '../AppComponents/Spinner.css';

const API_RESSOURCE = '/api/v1/projects/';

function Projects() {
    const queryParams = useLocation().search;
    var a =(new URLSearchParams(queryParams).get('year')) ;
    var b = (new URLSearchParams(queryParams).get('profession'));
    
    const [projects, setProjects] = useState([]);

    const [selectedPage, setSelectedPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [perPage, setPageLimit] = useState(12);
    const [pageCount, setPageCount] = useState(1);
    const [paginationVisibility, setPaginationVisibility] = useState(false);
    
    const [filterYear, setFilterYear] = useState(null);
    const [filterProf, setFilterProf] = useState(null);
    const [filterKeyword, setFilterKeyword] = useState(null);
    const [filter, setFilter] = useState({"year":a, "prof":b, "keyword":filterKeyword});
    const [filteredData, setFilteredData] = useState([]);
    //console.log(filter)
    const [lastYear, setLastYear] = useState();
    const [latestYear, setlatestYear] = useState();
    const [filterVisibility, setFilterVisibility] = useState(false);

    // const [lbOptions, setLBOptions] = useState({"visibility":false, "id":null})
    const [lbVisibility, setLBVisibility] = useState(false);
    const [lbProject, setLBProject] = useState(null);

    const handleKeyUp = ({key}) =>{
        //console.log("keycall " + key + " lbVisibility: " + lbVisibility)
        if (key === "Escape" && lbVisibility) {
            setLBVisibility(false);
        }
        if (key === "ArrowLeft" && lbVisibility) {
            //console.log("called arrowleftup while lightboxed")
            switchProject("left")
        }
        if (key === "ArrowRight" && lbVisibility) {
            //console.log("called arrowrightup while lightboxed")
            switchProject("right")
        }
    }
    useEffect(() => {
        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [lbVisibility,lbProject]);
    //console.log("Project.jsx code executed");
    const handlePageLimit = (e) => {
        setPageLimit(parseInt(e.target.value));
    }
    const handlePageClick = (e) => {
        setSelectedPage(e.selected);
    };
    const handleYearFilter = (e) => {
        e.target.value !== '' ? setFilter({"year":e.target.value,"prof":filter.prof,"keyword":filter.keyword}) : setFilterYear(null);
    }
    const handleProfFilter = (e) => {
        e.target.value !== '' ? setFilter({"year":filter.year,"prof":e.target.value,"keyword":filter.keyword}) : setFilterProf(null);
    }
    const handleTextFilter = (e) => {
        e.target.value !== '' ? setFilter({"year":filter.year,"prof":filter.prof,"keyword":e.target.value}) : setFilterKeyword(null);
    }

    const deleteKey = (key) => {
        switch (key) {
            case "prof":
                setFilter({"year":filter.year,"prof":null,"keyword":filter.keyword});
                break;
            case "year":
                setFilter({"year":null,"prof":filter.prof,"keyword":filter.keyword});
                break;
            case "keyword":
                setFilter({"year":filter.year,"prof":filter.prof,"keyword":null});
                break;
            default:
                break;
        }
    };

    const handleProjectClick = (project) => {
        setLBProject(project);
        setLBVisibility(true);
    }

    const switchProject = (d) => {
        //console.log(lbProject);
        switch (d) {
            case "left":
                if (projects.indexOf(lbProject)-1 >= 0) {
                    setLBProject(projects[projects.indexOf(lbProject)-1])
                }
                break;
            case "right":
                if (projects.indexOf(lbProject)+1 < projects.length) {
                    setLBProject(projects[projects.indexOf(lbProject)+1])
                }
                break;
            default:
                break;
        }
    }

    const cache = useRef({});  

    useEffect(() => {
        if(selectedPage > pageCount) setSelectedPage(0);
        setOffset(selectedPage * perPage);
    }, [pageCount, perPage, selectedPage]);

    useEffect(() => {
        const filterData = (data) => {
            let tempFilteredData = [];
            filteredData === [] ? setFilteredData(data) : tempFilteredData=data;
            Object.entries(filter).filter(e => e[1] !== null).forEach((f) => {
                if (f[0] === "year") {
                    tempFilteredData = tempFilteredData.filter(p => p.data?.year === parseInt(f[1]));
                } else if (f[0] === "prof") {
                    tempFilteredData = tempFilteredData.filter(p => p.data?.profession?.id === f[1]);
                } else {
                    f[1].split(" ").forEach(keyword => {
                        tempFilteredData = tempFilteredData.filter(p => p.data?.title?.toLowerCase().includes(keyword.toLowerCase()) || p.data?.description?.toLowerCase().includes(keyword.toLowerCase()));
                    });
                }
            });
            if (tempFilteredData.length == 0){
                tempFilteredData = "no project"
                setFilteredData("no project")
            }else{
                setFilteredData(tempFilteredData);
            }

            return tempFilteredData;
        }

        const fetchProjects = async () => {
            let data=[];
            let filteredData=[];
            if (cache.current[API_RESSOURCE]) {
                data = JSON.parse(cache.current[API_RESSOURCE]);
            } else {
                const res = await fetch(API_RESSOURCE,);
                data = await res.json();
                cache.current[API_RESSOURCE] = JSON.stringify(data);
            }
            filteredData = filterData(data);
            var slicedData
            if(filterData == "no project"){
                slicedData = "no project"
            } else{
                slicedData = filteredData.slice(offset, offset + perPage);
            }
            

            setProjects(slicedData);

            setLastYear(Math.min(...data.map(p => p.data.year)));
            setlatestYear(Math.max(...data.map(p => p.data.year)));

            setPageCount(Math.ceil(filteredData.length / perPage));
            setPaginationVisibility(true);
            setFilterVisibility(true);
        };
        fetchProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setProjects, perPage, projects.length, offset, filter]);

    return (
        <>
            <div className="app-child-node" id="home-wrapper">
                <Filter 
                    handleYearFilter={handleYearFilter}
                    handleProfFilter={handleProfFilter}
                    handleTextFilter={handleTextFilter}
                    lastYear={lastYear}
                    latestYear={latestYear}
                    filterVisibility={filterVisibility}
                    filter={filter}
                    deleteKey={deleteKey}
                >
                </Filter>
                <div id="projects-cards">                
                    {projects != "no project" && projects.length !== 0 ? projects.map((project, key) => {
                        return <Card project={project} key_index={key} onClick={() => {handleProjectClick(project)}}></Card>;
                    }): [
                            (projects != "no project"
                            ? <div class="projects-spinner"><div></div><div></div><div></div><div></div></div>
                            : <h1>Aucun projet pour ces filtres</h1>
                            )
                        ]
                    }         
                </div>                    
                <Pagination 
                    handlePageClick={handlePageClick}
                    handlePageLimit={handlePageLimit}
                    pageCount={pageCount}
                    paginationVisibility={paginationVisibility}
                >
                </Pagination>                        
            </div>
            <LightBox visibility={lbVisibility} setLBVisibility={setLBVisibility} project={lbProject} switchProject={switchProject}></LightBox>
        </>
    );
}

export default Projects;