import React from "react";
import './General404.css'

function General404() {
    return (
        <div className="app-child-node" id="home-wrapper">
            <div class="error404">
                <div class="e404imageCont"><div className="internalImage"><img class="e404image" src="/Assets/graylogo.svg"></img></div></div>
                <div class="e404titleCont"><span className="e404title">404 - Rien à voir ici</span></div>
                <div class="e404redirectCont"><span className="e404redirectLink"><a href="/">Retour à la page d'accueil</a></span></div>
            </div>
        </div>
    );
}

export default General404;